@use "sass:math";
@use "./_utilities/sass/functions";
@use "./_utilities/sass/variables/base";
@use "./_utilities/sass/variables/breakpoints";
@use "./_utilities/sass/variables/colours";

contact-form-block {
    position: relative;
    display: block;

    .message {
        background: colours.$orange;
        font-family: base.$font-stack-heading;
        font-weight: 500;
        padding: base.$line-height base.$line-height base.$line-height (base.$line-height * 3);
        position: relative;

        &--error {
            background: colours.$red;
            color: colours.$white;
        }
    }

    form {
        display: grid;
        grid-gap: base.$line-height;

        label {
            font-family: base.$font-stack-heading;
            font-weight: 500;
            display: block;

            &.is-invalid {
                input, textarea, select {
                    border-color: colours.$red;
                }

                .error {
                    color: colours.$red;
                    margin-top: base.$line-height * 0.25;
                }
            }
        }

        input:not([type="checkbox"]),
        textarea,
        select {
            font-size: inherit;
            display: block;
            margin: (base.$line-height * 0.125) 0 0;
            width: 100%;
        }

        textarea {
            height: functions.px-to-rem(350px);
        }

        input[type=checkbox] {
            position: absolute;
            z-index: -1000;
            left: -1000px;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            margin: -1px;
            padding: 0;
            border: 0;

            & + span {
                display: block;
                padding: 0 0 0 (base.$line-height * 1.5);
                position: relative;

                &::before {
                    box-sizing: border-box;
                    content: "";
                    display: inline-block;
                    height: base.$line-height;
                    width: base.$line-height;
                    background: colours.$white;
                    border: functions.px-to-rem(2px) solid colours.$gray;
                    position: absolute;
                    left: 0;
                }
            }

            &:focus + span {
                &::before {
                    border-color: colours.$orange;
                    box-shadow: 0 0 0 (math.div(base.$line-height, 8)) rgba(colours.$orange, 0.25);
                    outline: none;
                }
            }
        }
    }
}
