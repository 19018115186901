@use "_utilities.scss";
@use "_utilities/sass/base";
@use "_utilities/sass/stack";

@import './header';
@import './masthead';
@import "./content";
@import "./footer";
@import "./contact";

loki-block[type="Features.Newsroom.Components.NewsroomListBlock"] {
    @extend .three-grid
}


loki-block[type="Features.News.Components.NewsListBlock"],
loki-block[type="Features.Blog.Components.BlogListBlock"] {
    @extend .list
}

loki-block[type="Features.Shared.Components.ImageTextBlock"] {
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr 1fr;

    &.right {
        img {
            order: 1;
        }
    }
}
