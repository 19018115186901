@import "@freshleafmedia/privacy-banner/src/styles.scss";

@import "./video/index.scss";
@import "./code/index.scss";

body {
    font-family: "Work Sans", sans-serif;
}

h1, h2, h3, h4, h5 {
    font-family: "IBM Plex Serif", sans-serif;
    text-wrap: balance;
}

img {
    background-size: cover;
    max-width: 100%;
    height: auto;
}

loki-block {
    display: block;
}

time {
    white-space: nowrap;
}

@import "./demo/demo";
