
/**
 * Heavily inspired by https://github.com/paulirish/lite-youtube-embed
 */

video-embed {
    background-color: #000;
    position: relative;
    display: block;
    contain: content;
    background-position: center center;
    background-size: cover;
    cursor: pointer;

    &::before {
        background: linear-gradient(to bottom, rgba(black, 0), rgba(black, 0.75));
        bottom: 0;
        content: "";
        display: block;
        height: 50px;
        padding-bottom: 50px;
        position: absolute;
        width: 100%;
        z-index: 9;
    }

    & > iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        border: 0;
        z-index: 100;
    }

    & > .playbtn {
        border: 0;
        right: 50%;
        bottom: 50%;
        width: 50px;
        height: 50px;
        margin: -25px;
        position: absolute;
        cursor: pointer;
        z-index: 10;
        background-color: white;
        mask: url("/var/www/vhosts/demo.freshcontent.co.uk/web/resources/css/video/./images/icon-play.svg");
    }

    h3 {
        position: absolute;
        bottom: 35px;
        left: 35px;
        z-index: 15;
        color: #FFFFFF;
    }
}
