
@use "./_utilities/sass/variables/base";
@use "./_utilities/sass/variables/colours";

.main-content {
    & > * {
        margin-left: auto;
        margin-right: auto;
        padding-left: base.$line-height;
        padding-right: base.$line-height;
    }

    & > *:not(.full-width, .full-width-background, .full-columns) {
        max-width: base.$content-body-width;
    }

    & > .full-columns {
        max-width: base.$content-max-width;
    }

    & > .full-width-background {
        background: colours.$orange;
        padding: (base.$line-height * 3) 0;

        .full-width-background__container {
            margin: 0 auto;
            max-width: base.$content-max-width;
            padding-left: base.$line-height;
            padding-right: base.$line-height;
        }
    }

    ul:not([class]), ol:not([class]) {
        li {
            margin-left: base.$line-height;
        }
    }
}
