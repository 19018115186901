@use "sass:math";
@use "./_utilities/sass/functions";
@use "./_utilities/sass/variables/base";
@use "./_utilities/sass/variables/breakpoints";
@use "./_utilities/sass/variables/colours";

.site-header {
    font-family: base.$font-stack-heading;
    background: colours.$gray;
    color: colours.$black;
    padding: (base.$line-height) 0 base.$line-height;
    position: sticky;
    top: 0;
    z-index: 9;

    &__container {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: base.$content-max-width;
        padding: 0 base.$line-height;
        position: relative;
    }

    &__title {
        img {
            width: 80px;
        }
    }

    &__navigation {
        display: flex;
        align-content: end;
        align-items: end;

        & > ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;

            & > li {
                position: relative;

                & + li {
                    margin-left: base.$line-height * 1.5;
                }

                &.has-children > span,
                button {
                    -webkit-appearance: none;
                    background: none;
                    border: none;
                    color: inherit;
                    cursor: pointer;
                    font-family: inherit;
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    margin: 0;
                    padding: 0 base.$line-height 0 0;
                    position: relative;



                    &[aria-expanded="true"],
                    &:focus,
                    &:hover {
                        text-decoration: underline;
                    }
                }

                ul {
                    background: colours.$gray;
                    left: base.$line-height * -1;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    position: absolute;
                    opacity: 0;
                    top: 100%;
                    visibility: hidden;

                    &[aria-hidden="false"] {
                        opacity: 1;
                        transition: opacity 0.125s ease;
                        visibility: visible;
                    }

                    li {
                        padding: (base.$line-height * 0.5) base.$line-height;

                        & + li {
                            padding-top: 0;
                        }

                        span {
                            white-space: pre;
                        }
                    }
                }
            }

            a {
                color: inherit;
                text-decoration: none;
                white-space: pre;

                &:focus,
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
