@use "sass:color";
@use "sass:math";
@use "_utilities/sass/functions";
@use "_utilities/sass/variables/base";
@use "_utilities/sass/variables/breakpoints";
@use "_utilities/sass/variables/colours";

.list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: base.$line-height * 1.5;
    margin: 0;
    padding: 0;

    & > * {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        width: 100%;
    }
}

.three-grid {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: base.$line-height * 1.5;
    margin: 0;
    padding: 0;

    & > * {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        width: 100%;

        @media #{breakpoints.$media-medium-up} {
            width: calc(50% - #{base.$line-height * 0.75});
        }

        @media #{breakpoints.$media-x-large-up} {
            width: calc(#{percentage(math.div(1, 3))} - #{base.$line-height * 1.5 * math.div(2, 3)});
        }
    }
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    line-height: 1;
    list-style: none;
    margin: 0;
    padding: 0;

    & > * {
        a,span {
            cursor: pointer;
            height: 100%;
            margin: 0;
            background: inherit;
            border: functions.px-to-rem(2px) solid colours.$gray;
            display: inline-block;
            font-family: base.$font-stack-heading;
            font-size: inherit;
            font-weight: bold;
            padding: math.div(base.$line-height, 4) math.div(base.$line-height, 2);
            text-decoration: none;
        }

        @media #{breakpoints.$media-small-down} {
            & + * {
                margin-left: base.$line-height * 0.5;
            }

            &:first-child,
            &:last-child {
                display: none;
            }
        }

        @media #{breakpoints.$media-medium-up} {
            &:not(:first-child, :last-child) {
                a,span {
                    border-left: 0;
                    border-right: 0;
                }
            }
        }
    }
}
