@use "./_utilities/sass/functions";
@use "./_utilities/sass/variables/base";
@use "./_utilities/sass/variables/breakpoints";
@use "./_utilities/sass/variables/colours";

.site-footer {
    font-family: base.$font-stack-heading;
    background: colours.$gray;
    color: colours.$black;
    padding: (base.$line-height * 2) base.$line-height;

    @media #{breakpoints.$media-x-large-up} {
        padding-bottom: base.$line-height * 5;
    }

    a {
        color: inherit;
        text-decoration: none;
        white-space: pre;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        max-width: base.$content-max-width;

        @media #{breakpoints.$media-x-large-up} {
            flex-direction: row;
            justify-content: space-between;
        }

        & > * + * {
            @media #{breakpoints.$media-large-down} {
                margin-top: base.$line-height;
            }
        }
    }

    &__copyright {
        text-align: center;

        @media #{breakpoints.$media-x-large-up} {
            text-align: left;
        }

        img {
            display: inline-block;
            width: 60px;
        }
    }

    &__navigation {
        ul {
            align-items: center;
            display: flex;
            flex-direction: column;
            list-style: none;
            margin: 0;
            padding: 0;

            @media #{breakpoints.$media-medium-up} {
                flex-direction: row;
                justify-content: center;
            }

            @media #{breakpoints.$media-x-large-up} {
                justify-content: flex-end;
            }

            li {
                position: relative;

                & + li {
                    @media #{breakpoints.$media-small-down} {
                        margin-top: base.$line-height * 0.5;
                    }

                    @media #{breakpoints.$media-medium-up} {
                        margin-left: base.$line-height;
                    }

                    @media #{breakpoints.$media-large-up} {
                        margin-left: base.$line-height * 1.5;
                    }
                }
            }
        }
    }
}
