@use "sass:color";
@use "./_utilities/sass/functions";
@use "./_utilities/sass/variables/base";
@use "./_utilities/sass/variables/breakpoints";
@use "./_utilities/sass/variables/colours";

.page-masthead {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: functions.px-to-rem(100px);
    position: relative;
    padding: 0 !important;

    hgroup {
        margin: auto auto 0;
        max-width: base.$content-max-width;
        padding: 0 base.$line-height;
        text-align: left;
        width: 100%;
        z-index: 5;

        @media #{breakpoints.$media-large-down} {
            text-align: center;
        }

        h2 {
            @media #{breakpoints.$media-medium-down} {
                font-size: functions.px-to-rem(28px);
            }

            @media #{breakpoints.$media-x-large-up} {
                margin-left: 0;
                width: 40ch;
            }
        }
    }

    img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &--blogpost {
        &::after {
            position: absolute;
        }

        hgroup {
            margin: auto;
        }

        h2 {
            @media #{breakpoints.$media-large-up} {
                width: 100%;
            }
        }
    }
}
