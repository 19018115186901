@use "sass:math";
@use "functions";
@use "variables/base";
@use "variables/breakpoints";
@use "variables/colours";

* {
    box-sizing: border-box;
}

a {
    color: colours.$red;

    &:active,
    &:focus,
    &:hover {
        color: inherit;
    }

    &:focus {
        outline: (base.$one-pixel-in-rem * 3) solid currentColor;

        &:not(:focus-visible) {
            outline: none;
        }
    }
}

blockquote {
    font-style: italic;
    margin: 0;

    &.blockquote--no-italics {
        font-style: normal;
    }
}

body {
    background: colours.$white;
    color: colours.$black;
    font-family: base.$font-stack-body;
    line-height: base.$line-height;
    overflow-x: hidden;
    margin: 0;

    @at-root {
        html.html--show-navigation & {
            @media #{breakpoints.$media-hamburger-down} {
                overflow: hidden;
            }
        }
    }

    & > * + .site-footer {
        margin-top: base.$line-height * 4;
    }
}

button {
    line-height: math.div(5, 3);
    color: inherit;
}

figcaption {
    margin: 0;
    padding: 0;
}

figure {
    margin: 0;

    blockquote + figcaption {
        font-weight: bold;
    }

    picture + figcaption {
        font-style: italic;
        margin-top: base.$line-height * 0.5;
        text-align: center;
    }
}

html {
    overflow-x: hidden;

    &:not([class*="is-preview"]) {
        scroll-behavior: smooth;
    }
}

h1, h2, h3, h4 {
    font-family: base.$font-stack-heading;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

h1 {
    font-size: percentage(math.div(36px, base.$font-size));
    line-height: 1.125;
    letter-spacing: functions.px-to-rem(-1px);

    @media #{breakpoints.$media-large-up} {
        font-size: percentage(math.div(64px, base.$font-size));
        line-height: 1;
    }
}

h2 {
    font-size: percentage(math.div(36px, base.$font-size));
    line-height: 1;
    letter-spacing: functions.px-to-rem(-1px);
}

h3 {
    font-size: percentage(math.div(32px, base.$font-size));
    line-height: 1.125;
    letter-spacing: functions.px-to-rem(-1px);

    a {
        font-weight: 500 !important;
    }
}

h4 {
    font-size: 112.5%;
    line-height: inherit;
}

hgroup {
    &.hgroup--large {
        h2, p {
            font-family: base.$font-stack-body;
            font-size: percentage(math.div(36px, base.$font-size));
            line-height: math.div(48px, 36px);
            letter-spacing: 0;

            @media #{breakpoints.$media-large-up} {
                margin-left: auto;
                margin-right: auto;
                width: 30ch;
            }
        }

        p {
            font-size: percentage(math.div(20px, base.$font-size));
            margin-top: base.$line-height * 0.5;

            @media #{breakpoints.$media-large-up} {
                font-size: percentage(math.div(28px, base.$font-size));
            }
        }
    }


    &.hgroup--centered {
        text-align: center !important;
    }
}

img {
    display: block;
    width: 100%;
    height: auto;
    background-size: cover;
}

p, ol, ul {
    line-height: math.div(5, 3);
    margin: 0;
    padding: 0;
}

ul, ol{
    ul, ol {
        font-size: 100%;
    }
}
