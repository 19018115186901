@use "variables/base";

.stack {
    & > * + * {
        margin-top: base.$line-height;
    }

    & > * + h2 {
        margin-top: base.$line-height * 1.5;
    }

    & > h2 + p.largest,
    & > h2 + .subheading {
        margin-top: base.$line-height * 0.5;
    }

    & > blockquote + figcaption {
        margin-top: base.$line-height * 0.5;
    }

    & > h1 + h2 {
        margin-top: 1rem;
    }

    & > h3 + h4 {
        margin-top: base.$line-height * 0.125;
    }

    & > .hgroup--large,
    & > .hgroup--large + * {
        margin-top: base.$line-height * 5;
    }

    & > .hgroup--large + .error-404-image,
    & > .error-404-image + * {
        margin-top: base.$line-height * 2;
    }

    & > p + p,
    & > p + label,
    & > p + ul,
    & > p + ol,
    & > ul + p,
    & > ol + p {
        margin-top: base.$line-height * 0.5;
    }

    & > * + figure,
    & > * + .image-and-text,
    & > * + .video-embed,
    & > * + .logo-splash,
    & > * + .latest-insights__grid,
    & > figure + *,
    & > .image-and-text + *,
    & > .video-embed + *,
    & > .logo-splash + * {
        margin-top: base.$line-height * 1.5;
    }

    & > * + .full-columns,
    & > * + .full-width,
    & > * + .full-width-background,
    & > * + .homepage-ctas,
    & > .full-columns + *,
    & > .full-width + *,
    & > .full-width-background + *,
    & > .homepage-ctas + * {
        margin-top: base.$line-height * 3;
    }

    & > .blogpost-spotlight + * {
        margin-top: base.$line-height * 5;
    }

    & > .page-masthead + * {
        margin-top: base.$line-height * 3;
    }

    & > .three-grid + nav {
        margin-top: base.$line-height * 1.5;
    }

    &--card {
        & > figure + *,
        & > .metadata + * {
            margin-top: base.$line-height;
        }

        & > * + .metadata {
            margin-top: base.$line-height * 0.5;
        }
    }

    &--homepage {
        & > * + .full-columns,
        & > * + .full-width,
        & > * + .full-width-background,
        & > * + .homepage-ctas,
        & > .full-columns + *,
        & > .full-width + *,
        & > .full-width-background + *,
        & > .homepage-ctas + * {
            margin-top: base.$line-height * 4;
        }

        & > .page-masthead + * {
            margin-top: base.$line-height * 3;
        }

        & > .blogpost-spotlight + * {
            margin-top: base.$line-height * 6.5;
        }
    }
}
