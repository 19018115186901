@use "sass:math";

$font-size: 16px;
$font-stack-body: "IBM Plex Serif", serif;
$font-stack-heading: "Work Sans", sans-serif;
$line-height: 1.5rem;

$one-em-in-pixels: 16px;
$one-pixel-in-em: math.div(1px, $one-em-in-pixels) * 1em;
$one-pixel-in-rem: math.div(1px, $one-em-in-pixels) * 1rem;

$content-max-width: 1036px + ($font-size * 3);
$content-body-width: 860px + ($font-size * 3);
